<template>
  <b-modal v-model="showModal" hide-footer hide-header centered>
    <b-overlay spinner-variant="primary" :show="isLoading">
      <b-alert show variant="danger" class="mt-2 py-1 px-1">
        <h4>Увага! Користувач буде мати доступ до адмін панелі сервісу.</h4>
        <p>Ви справді хочете зробити цього користувача адміністратором</p>
      </b-alert>
      <div class="cta mb-2">
        <b-button variant="danger" @click="makeAdmin" block size="lg">
          Зробити адміністратором
        </b-button>
        <b-button
          block
          class="mt-1"
          variant="outline-primary"
          @click="closeModal"
        >
          Скасувати
        </b-button>
      </div>
    </b-overlay>
  </b-modal>
</template>

<script>
export default {
  props: ["show", "uid"],
  data() {
    return {
      isLoading: false,
    };
  },
  computed: {
    showModal() {
      return this.show;
    },
  },
  methods: {
    closeModal() {
      this.$emit("close");
    },
    async makeAdmin() {
      this.isLoading = true;
      await this.$api
        .get("/user/setAdmin/?uid=" + this.uid)
        .then((response) => {
          if (response.data.code == "success") {
            this.$toast.success("Роль змінено");
            this.$emit("update");
          }
        })
        .catch((error) => {
          console.log(error);
        });

      this.isLoading = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
