<template>
  <div>
    <b-overlay spinner-variant="primary" :show="isLoading">
      <b-card>
        <div class="d-flex align-items-center">
          <b-avatar
            v-if="user.first_name && user.last_name"
            variant="light-primary"
            class="mr-1"
            size="lg"
            :text="user.first_name[0] + user.last_name[0]"
          ></b-avatar>
          <div class="info d-flex flex-wrap align-items-center w-100">
            <h1 class="mb-0 mr-1">
              {{ user.first_name }} {{ user.last_name }}
            </h1>
            <div class="badges mt-0 ml-auto">
              <b-badge v-if="user.isAdmin" variant="danger" class="mr-1">
                Адміністратор
              </b-badge>
              <b-badge v-if="user.isWorker" variant="primary" class="mr-1">
                Працівник
              </b-badge>
            </div>
          </div>
        </div>
      </b-card>

      <div class="row">
        <div class="col-12 col-md-4">
          <b-card title="E-mail">
            <a :href="'mailto:' + user.email">{{ user.email }}</a>
          </b-card>
        </div>
        <div class="col-12 col-md-4">
          <b-card title="Дата реєстрації">
            <span v-if="user.first_visit && user.first_visit.seconds">
              {{ user.first_visit.seconds | moment("DD.MM.YYYY HH:mm") }}
            </span>
            <span v-else-if="user.first_visit">
              {{ user.first_visit | moment("DD.MM.YYYY HH:mm") }}
            </span>
          </b-card>
        </div>
        <div class="col-12 col-md-4">
          <b-card title="Номер телефону">
            <a v-if="user.phone_number" :href="'tel:' + user.phone_number">{{
              user.phone_number
            }}</a>
            <span v-else>--</span>
          </b-card>
        </div>
        <div class="col-12 col-md-4">
          <b-card title="Ост. візит">
            <span v-if="user.last_visit && user.last_visit.seconds">
              {{ user.last_visit.seconds | moment("DD.MM.YYYY HH:mm") }}
            </span>
            <span v-else-if="user.last_visit">
              {{ user.last_visit | moment("DD.MM.YYYY HH:mm") }}
            </span>
            <span v-else>--</span>
          </b-card>
        </div>
        <div class="col-12">
          <b-card title="Редагувати дані">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Імʼя</label>
                  <b-form-input v-model="user.first_name"> </b-form-input>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>Прізвище</label>
                  <b-form-input v-model="user.last_name"> </b-form-input>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label>По-батькові</label>
                  <b-form-input v-model="user.middle_name"> </b-form-input>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <div class="form-group">
                  <label class="d-block" style="opacity: 0"> Зберегти</label>
                  <b-button
                    @click="updateName"
                    variant="primary"
                    class="d-block w-100"
                  >
                    Оновити дані
                  </b-button>
                </div>
              </div>
            </div>
          </b-card>
        </div>
        <div class="col-12" v-if="user.isWorker">
          <b-card>
            <div class="form-group">
              <h3 class="mb-1">Посада, на яку призначити працівника</h3>
              <v-select
                id="department-parent"
                v-model="user.department"
                :options="departments"
                :reduce="(text) => text.value"
                label="text"
                placeholder="Оберіть посаду"
              />
            </div>
            <div class="d-flex justify-content-end">
              <b-button variant="primary" @click="setDepartment">
                Призначити посаду
              </b-button>
            </div>
          </b-card>
          <b-card>
            <h3 class="mb-1">Графік прийому</h3>
            <div class="form-group">
              <b-form-checkbox
                id="checkbox-schedule"
                v-model="user.has_schedule"
                name="checkbox-schedule"
                :value="true"
              >
                Має графік прийому
              </b-form-checkbox>
            </div>
            <template v-if="user.has_schedule">
              <div class="form-group">
                <h4>Обідня перерва</h4>
                <date-picker
                  v-model="coffee_break.start"
                  :time-picker-options="time_picker_options"
                  value-type="format"
                  type="time"
                  format="HH:mm"
                ></date-picker>
                -
                <date-picker
                  v-model="coffee_break.end"
                  :time-picker-options="time_picker_options"
                  value-type="format"
                  type="time"
                  format="HH:mm"
                ></date-picker>
              </div>
              <div class="form-group schedule mt-4">
                <h4>Прийомні дні</h4>
                <div
                  class="schedule-item d-flex"
                  v-for="d in days"
                  :key="d.day"
                >
                  <b-form-checkbox
                    :id="'day-' + d.day"
                    v-model="d.active"
                    :name="'day-' + d.day"
                    value="true"
                  >
                  </b-form-checkbox>
                  <label class="day-name" :for="'day-' + d.day">
                    <strong>
                      {{ d.name }}
                    </strong>
                  </label>
                  <div class="hours-start">
                    з
                    <date-picker
                      :disabled="!d.active"
                      v-model="d.start_hour"
                      :time-picker-options="time_picker_options"
                      value-type="format"
                      type="time"
                      format="HH:mm"
                    ></date-picker>
                  </div>
                  <div class="hours-end">
                    по
                    <date-picker
                      :disabled="!d.active"
                      v-model="d.end_hour"
                      value-type="format"
                      :time-picker-options="time_picker_options"
                      type="time"
                      format="HH:mm"
                    ></date-picker>
                  </div>
                  <div class="ml-auto every d-flex align-items-center">
                    <b-form-select
                      class="mr-1"
                      :disabled="!d.active"
                      v-model="d.every_of_month"
                    >
                      <b-form-select-option
                        :value="o.value"
                        v-for="o in every_options"
                        :key="o.value"
                      >
                        {{ o.text }}
                      </b-form-select-option>
                    </b-form-select>
                    i
                    <b-form-select
                      class="ml-1"
                      :disabled="!d.active || !d.every_of_month"
                      v-model="d.every_of_month_alt"
                    >
                      <b-form-select-option
                        :value="o.value"
                        v-for="o in every_options"
                        :key="o.value"
                      >
                        {{ o.text }}
                      </b-form-select-option>
                    </b-form-select>
                  </div>
                </div>
              </div>
            </template>
            <div class="d-flex justify-content-end mt-2">
              <b-button @click="setSchedule" variant="primary">
                Оновити графік
              </b-button>
            </div>
          </b-card>
        </div>
        <div class="col-12 d-flex">
          <b-button @click="openRemovalModal" variant="outline-danger">
            Видалити користувача
          </b-button>
          <b-button
            v-if="!user.isWorker"
            variant="primary"
            class="ml-auto"
            @click="openMakingWorkerModal"
          >
            Зробити працівником
          </b-button>
          <b-button
            v-if="!user.isAdmin"
            variant="warning"
            class="ml-2"
            @click="openMakingAdminModal"
          >
            Зробити адмінстратором
          </b-button>
        </div>
      </div>
    </b-overlay>
    <make-worker-modal
      @close="closeModals"
      @update="roleChanged"
      :show="makingWorkerModal"
      :uid="user.uid"
    />
    <make-admin-modal
      @update="roleChanged"
      @close="closeModals"
      :show="makingAdminModal"
      :uid="user.uid"
    />
    <removeUserModal
      @update="userRemoved"
      @close="closeModals"
      :show="removingModal"
      :uid="user.uid"
    />
  </div>
</template>

<script>
import makeWorkerModal from "./modals/make-worker.vue";
import makeAdminModal from "./modals/make-admin.vue";
import removeUserModal from "./modals/remove-user.vue";
import vSelect from "vue-select";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
export default {
  components: {
    makeWorkerModal,
    makeAdminModal,
    removeUserModal,
    vSelect,
    DatePicker,
  },
  data() {
    return {
      isLoading: false,
      user: {},
      removingModal: false,
      makingAdminModal: false,
      makingWorkerModal: false,
      all_departments: [],
      coffee_break: {
        start: "12:00",
        end: "13:00",
      },
      every_options: [
        {
          text: "немає",
          value: null,
        },
        {
          text: "Кожен 1 в місяці",
          value: 1,
        },
        {
          text: "Кожен 2 в місяці",
          value: 2,
        },
        {
          text: "Кожен 3 в місяці",
          value: 3,
        },
        {
          text: "Кожен 4 в місяці",
          value: 4,
        },
        {
          text: "Останній в місяці",
          value: "last",
        },
      ],
      time_picker_options: {
        start: "08:00",
        step: "00:15",
        end: "18:00",
        format: "HH:mm",
      },
      days: [
        {
          day: 1,
          name: "Понеділок",
          active: false,
          every_of_month: null,
          every_of_month_alt: null,
          start_hour: "08:00",
          end_hour: "18:00",
          coffee_break: {
            start: "12:00",
            end: "13:00",
          },
        },
        {
          day: 2,
          name: "Вівторок",
          active: false,
          every_of_month: null,
          every_of_month_alt: null,
          start_hour: "08:00",
          end_hour: "18:00",
          coffee_break: {
            start: "12:00",
            end: "13:00",
          },
        },
        {
          day: 3,
          name: "Середа",
          active: false,
          every_of_month: null,
          every_of_month_alt: null,
          start_hour: "08:00",
          end_hour: "18:00",
          coffee_break: {
            start: "12:00",
            end: "13:00",
          },
        },
        {
          day: 4,
          name: "Четвер",
          active: false,
          every_of_month: null,
          every_of_month_alt: null,
          start_hour: "08:00",
          end_hour: "18:00",
          coffee_break: {
            start: "12:00",
            end: "13:00",
          },
        },
        {
          day: 5,
          name: "Пʼятниця",
          active: false,
          every_of_month: null,
          every_of_month_alt: null,
          start_hour: "08:00",
          end_hour: "18:00",
          coffee_break: {
            start: "12:00",
            end: "13:00",
          },
        },
        {
          day: 6,
          name: "Субота",
          active: false,
          every_of_month: null,
          every_of_month_alt: null,
          start_hour: "08:00",
          end_hour: "18:00",
          coffee_break: {
            start: "12:00",
            end: "13:00",
          },
        },
        {
          day: 7,
          name: "Неділя",
          active: false,
          every_of_month: null,
          every_of_month_alt: null,
          start_hour: "08:00",
          end_hour: "18:00",
          coffee_break: {
            start: "12:00",
            end: "13:00",
          },
        },
      ],
    };
  },
  watch: {
    coffee_break: {
      deep: true,
      handler(newVal) {
        for (var key in this.days) {
          this.days[key].coffee_break = newVal;
        }
      },
    },
  },
  computed: {
    departments() {
      let list = this.all_departments.filter((e) => {
        return e.is_position;
      });
      list = list.map((e) => {
        return { text: e.name, value: e.id };
      });

      //Not allow to be parent of self
      return list;
    },
  },
  methods: {
    openRemovalModal() {
      this.removingModal = true;
    },
    async updateName() {
      this.isLoading = true;
      var data = {
        first_name: this.user.first_name,
        last_name: this.user.last_name,
        middle_name: this.user.middle_name,
      };
      await this.$db.update("users", this.user.uid, data);
      this.$toast.success("Дані оновлено");
      this.isLoading = false;
    },
    async setSchedule() {
      this.isLoading = true;
      var data = {
        has_schedule: this.user.has_schedule,
        schedule: this.days,
      };
      await this.$db.update("users", this.user.uid, data);
      this.$toast.success("Графік оновлено");
      this.isLoading = false;
    },
    async setDepartment() {
      this.isLoading = true;
      var data = {
        department: this.user.department,
      };
      await this.$db.update("users", this.user.uid, data);
      await this.updateNameInDepartment();

      this.isLoading = false;
    },
    async updateNameInDepartment() {
      this.isLoading = true;
      let name = this.user.last_name + " " + this.user.first_name[0] + ".";
      if (this.user.middle_name && this.user.middle_name.length > 2) {
        name = name + " " + this.user.middle_name + ".";
      }
      let data = {
        current_worker_name: name,
      };

      let dept = this.all_departments.find((el) => {
        return Number(el.id) == Number(this.user.department);
      });
      await this.$db.update("departments", dept.uid, data);
      this.$toast.success("Посаду оновлено");
      this.isLoading = false;
    },
    async getDepartments() {
      this.isLoading = true;
      let args = {
        collectionName: "departments",
      };
      var result = await this.$db.getCollection(args);
      this.all_departments = result.docs;
      this.isLoading = false;
    },
    closeModals() {
      this.makingWorkerModal = false;
      this.makingAdminModal = false;
      this.removingModal = false;
    },
    userRemoved() {
      this.closeModals();
      this.$router.push({ name: "users-all" });
    },
    roleChanged() {
      this.closeModals();
      this.getUser();
    },
    async getUser() {
      this.isLoading = true;
      let id = Number(this.$route.params.id);
      let conditions = ["id", "==", id];
      let result = await this.$db.getCollectionWhere("users", conditions);
      if (result.length) {
        this.user = result[0];
        if (result[0].schedule) {
          this.days = result[0].schedule.map((el) => {
            let item = {
              day: el.day,
              name: el.name,
              active: el.active,
              every_of_month: el.every_of_month ? el.every_of_month : null,
              every_of_month_alt: el.every_of_month_alt
                ? el.every_of_month_alt
                : null,
            };
            item["start_hour"] = el.start_hour ? el.start_hour : "00:00";
            item["end_hour"] = el.end_hour ? el.end_hour : "00:00";
            item["coffee_break"] = el.coffee_break
              ? el.coffee_break
              : {
                  start: "12:00",
                  end: "13:00",
                };
            this.coffee_break = item.coffee_break;
            return item;
          });
        }
        if (this.user.isWorker) {
          this.getDepartments();
        }
      }
      this.isLoading = false;
    },
    openMakingWorkerModal() {
      this.makingWorkerModal = true;
    },
    openMakingAdminModal() {
      this.makingAdminModal = true;
    },
  },
  mounted() {
    this.getUser();
  },
};
</script>

<style lang="scss">
@import "~@core/scss/vue/libs/vue-select.scss";
.mx-datepicker {
  width: 140px;
}
.badges {
  * {
    line-height: 1;
    text-transform: lowercase;
  }
}
.schedule-item {
  padding: 12px 6px;
  border-bottom: 1px solid #e7e7e7;
  align-items: center;
  .day-name {
    width: 120px;
  }
  .hours-start {
    margin: 0px 10px;
  }
}
</style>
