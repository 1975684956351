import { render, staticRenderFns } from "./make-admin.vue?vue&type=template&id=289aa80b&scoped=true&"
import script from "./make-admin.vue?vue&type=script&lang=js&"
export * from "./make-admin.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "289aa80b",
  null
  
)

export default component.exports